import { store } from '@/store/store';
import { pantalla_modo_lista } from '@/shared/dtos/dynamic_tables/pantalla_modo_lista';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'pantalla_modo_listaModule',
    store,
    dynamic: true
})
class pantalla_modo_listaModule extends VuexModule {
    public pantalla_modo_lista: pantalla_modo_lista = new pantalla_modo_lista();

    @Action({ commit: 'onGetpantalla_modo_lista' })
    public async getpantalla_modo_lista(id: any) {
        return await ssmHttpService.get(API.pantalla_lista + '/pantalla/' + id);
    }

    @Mutation
    public onGetpantalla_modo_lista(res: pantalla_modo_lista) {
        this.pantalla_modo_lista = res;
    }

}
export default getModule(pantalla_modo_listaModule);